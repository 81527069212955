<template>
  <div :class="$style.container">
    <Loader :isLoading="isLoader" />
    <vue-good-table
      :columns="columns"
      :rows="list"
      styleClass="vgt-table striped"
      fixed-header
    >
      <template slot="table-row" slot-scope="props">
        <router-link
          v-if="props.column.field == 'link'"
          :to="`suppliers/${props.row.id}/edit`"
        >
          Перейти к каталогу
        </router-link>
      </template>
    </vue-good-table>
    <Pagination
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="currentPage"
      :lastPage="lastPage"
    />
    <Loader :isLoading="isLoader" />
  </div>
</template>

<script>
import delivery from '@/delivery'
import Loader from '@/components/atoms/LoadingBar.vue'
import Pagination from '@/components/atoms/Paginations.vue'

export default {
  components: {
    Loader,
    Pagination,
  },
  mounted() {
    this.getList()
  },
  data() {
    return {
      list: null,
      columns: [
        {
          label: 'Название поставщика',
          field: 'name',
          tooltip: 'Название поставщика',
          sortable: false,
        },
        {
          label: '',
          field: 'link',
          sortable: false,
        },
      ],
      currentPage: 1,
      limitPage: 10,
      lastPage: 1,
      isLoader: false,
    }
  },
  methods: {
    async getList() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.SuppliersActions.getList({
          page: this.currentPage,
          limit: this.limitPage,
        })
      if (error) return
      this.list = value.data
      this.lastPage = Math.ceil(value.meta.count / this.limitPage)
      this.isLoading = false
    },
    async goNext() {
      if (this.currentPage < this.lastPage) {
        this.currentPage++
        await this.getList()
      }
    },
    async goPrev() {
      if (this.currentPage > 1) {
        this.currentPage--
        await this.getList()
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  overflow: hidden;
}
</style>
